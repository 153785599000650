@charset "UTF-8";
@import '@coreui/coreui/dist/css/coreui.min.css';
@import url("https://fonts.cdnfonts.com/css/louis-george-cafe");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@keyframes openMenu {
  from {
    opacity: 0;
    transform: translateX(-25px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
* {
  font-family: "Louis George Café";
}

* {
  box-sizing: border-box;
}

body {
  width: 100%;
  background-color: #F5EFF0;
}

.background-block__header {
  background-color: white;
  position: fixed;
  z-index: 4;
  width: 100vw;
}
.background-block__margin-header {
  background-color: white;
  height: 85px;
}
@media screen and (max-width: 768px) {
  .background-block__margin-header {
    height: 79.52px;
  }
}
.background-block__main {
  background: #f6f7fa;
}
.background-block__footer {
  background-color: #F5EFF0;
}
.background-block main {
  margin: auto;
  max-width: 100%;
}

header {
  margin: auto;
  max-width: 1200px;
  display: flex;
}
@media screen and (max-width: 768px) {
  header {
    width: 100%;
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
header .logo {
  width: 200px;
  height: fit-content;
}
@media screen and (max-width: 768px) {
  header .logo {
    width: 160px;
    margin: 8px;
  }
}
header .menu-icon {
  display: none;
  font-size: 24px;
  height: 100%;
  background-color: #f5eff0;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  margin: 26px 16px 0px 16px;
  align-items: center;
}
@media screen and (max-width: 768px) {
  header .menu-icon {
    display: block;
  }
}
header .menu-icon svg {
  margin: 4px 7px;
  width: 18px;
}
@media screen and (max-width: 768px) {
  header nav {
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.7s ease 0.2s, transform 0.5s ease 0.3s;
    opacity: 0;
    transform: translateX(-80px);
  }
  header nav.menu-open {
    max-height: 224px; /* Ajoutez une animation de descente de 0.5s avec une fonction d'interpolation 'ease' */
    opacity: 1;
    transform: translateX(0);
  }
}
header nav ul {
  display: flex;
  list-style: none;
  padding-inline-start: 0;
  margin-top: 12px;
}
@media screen and (max-width: 768px) {
  header nav ul {
    flex-wrap: wrap;
    margin-bottom: 8px;
    margin-top: 0px;
  }
}
header nav ul li {
  padding: 1em;
  transition: border-bottom 0.25s ease;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
@media screen and (max-width: 768px) {
  header nav ul li {
    width: 100%;
    font-size: 12px;
    padding: 4px 20px;
    border-bottom: 0px;
  }
}
header nav ul li:hover {
  border-bottom: 1px solid black;
}
@media screen and (max-width: 768px) {
  header nav ul li:hover {
    border-bottom: 0px;
  }
}
header nav ul li:has(.activeSection) {
  border-bottom: 1px solid black;
}
@media screen and (max-width: 768px) {
  header nav ul li:has(.activeSection) {
    background-color: #f5eff0;
    border-bottom: 0px;
  }
}
header nav ul li a {
  font-family: "Playfair Display";
  text-decoration: none;
  color: black;
}

footer {
  display: flex;
  justify-content: space-between;
  min-height: 80px;
  margin: auto;
  padding-top: 30px;
  max-width: 1200px;
  min-height: 80px;
}
@media screen and (max-width: 768px) {
  footer {
    max-width: 100%;
    flex-wrap: wrap;
    padding-top: 15px;
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  footer div {
    text-align: center;
    width: 100%;
  }
}
footer ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  color: #524949;
}
@media screen and (max-width: 768px) {
  footer ul {
    flex-wrap: wrap;
    justify-content: center;
    margin: 10px;
  }
}
footer ul li {
  margin: 0 0 0 18px;
}
@media screen and (max-width: 768px) {
  footer ul li {
    margin: 2px 4px;
  }
}
footer ul li a {
  font-family: "Playfair Display";
  text-decoration: none;
  color: black;
}

.carousel {
  width: 60%;
  max-width: 900px;
  margin: auto;
}
@media screen and (max-width: 768px) {
  .carousel {
    width: 90%;
    max-width: none;
  }
}

.carousel-box {
  padding-bottom: 32px;
}

.button {
  display: block;
  font-style: normal;
  text-decoration: none;
  font-weight: bold;
  width: fit-content;
  border-radius: 64px;
  margin: 0px auto 32px auto;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.23);
}
.button--bookmark {
  background-color: #4d4547;
  color: white;
  font-size: 24px;
  margin: 0px auto 32px auto;
  padding: 16px 32px;
}
@media screen and (max-width: 768px) {
  .button--bookmark {
    font-size: 20px;
    padding: 12px 24px;
    margin: 0 auto 24px auto;
  }
}
.button--bookmark:hover {
  background-color: #837579;
  color: #171515;
}
.button--tarif {
  background-color: white;
  color: #524949;
  font-size: 20px;
  margin: 12px 48px;
  padding: 12px 24px;
}
@media screen and (max-width: 768px) {
  .button--tarif {
    font-size: 18px;
    padding: 8px 16px;
    margin: 8px 38px;
  }
}
.button--tarif:hover {
  background-color: #ceb0b5;
  color: #171515;
}
.button--contact {
  background-color: #4d4547;
  color: white;
  font-size: 24px;
  margin: 32px auto;
  padding: 16px 64px;
  border: none;
}
@media screen and (max-width: 768px) {
  .button--contact {
    font-size: 20px;
    padding: 12px 48px;
    margin: 12px auto 24px auto;
  }
}
.button--contact:hover {
  background-color: #837579;
  color: #171515;
}

.liste-tarifs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.liste-tarifs .cardfee {
  background-color: white;
  width: 48%;
  margin: 1%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.23);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .liste-tarifs .cardfee {
    width: 90%;
    margin: 3% 5%;
  }
}
.liste-tarifs .cardfee h2 {
  font-size: 32px;
  font-weight: 200;
  box-sizing: border-box;
  text-align: center;
  margin: 0;
  padding: 10px 0;
  width: 100%;
  height: fit-content;
  background-color: #F5EFF0;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.23);
  z-index: 2;
}
.liste-tarifs .cardfee h2 .title-info {
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  margin: 0;
  padding: 0;
  line-height: 12px;
}
.liste-tarifs .cardfee ul {
  width: 100%;
  padding-top: 10px;
  padding-left: 24px;
}
.liste-tarifs .cardfee .tarif {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4d4547;
  color: white;
  box-shadow: 0 -3px 3px 0px rgba(0, 0, 0, 0.23);
}
.liste-tarifs .cardfee .tarif div {
  width: 50%;
  height: fit-content;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .liste-tarifs .cardfee .tarif div {
    font-size: 25px;
  }
}

#map {
  width: 700px;
  height: 400px;
  margin: 30px auto;
}
@media screen and (max-width: 768px) {
  #map {
    width: 95%;
    height: 300px;
  }
}

.page {
  max-width: 1024px;
  margin: auto;
  padding: 10px 0 15px 0;
}
@media screen and (max-width: 768px) {
  .page {
    padding: 10px;
  }
}
.page h1 {
  font-size: 60px;
  font-weight: 200;
  box-sizing: border-box;
  text-align: center;
  margin: 0 auto 40px auto;
  padding: 10px 0;
  width: 100%;
  background-color: #F5EFF0;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.23);
}
@media screen and (max-width: 768px) {
  .page h1 {
    font-size: 45px;
    font-weight: 100;
  }
}
.page p {
  line-height: 28px;
  font-size: 18px;
  color: #524949;
  text-align: justify;
}
@media screen and (max-width: 768px) {
  .page p {
    line-height: 24px;
    font-size: 16px;
  }
}

.introduction {
  background-image: url("assets/1_Accueil/banner.jpg");
  background-size: auto 100%; /* Ajuste la largeur à 100% et la hauteur automatiquement */
  background-repeat: no-repeat;
  background-position: center center; /* Centre l'image dans le bloc */
  width: 100%;
  height: 1080px;
  z-index: 1;
  display: flex; /* Utilise Flexbox pour le premier bloc */
  align-items: center; /* Centre verticalement */
  justify-content: center; /* Centre horizontalement */
}
@media screen and (max-width: 768px) {
  .introduction {
    height: 540px;
  }
}
.introduction .alpha-grey {
  width: inherit;
  height: inherit;
  position: absolute;
  background-color: rgba(40, 40, 40, 0.6);
  z-index: 2;
}
.introduction__welcome {
  position: relative;
  z-index: 3;
  color: white;
  vertical-align: middle;
  display: flex; /* Utilise Flexbox pour le premier bloc */
  align-items: center; /* Centre verticalement */
  justify-content: center; /* Centre horizontalement */
  flex-wrap: wrap;
}
.introduction__welcome h1 {
  font-family: "Playfair Display";
  width: 100%;
  text-align: center;
  font-size: 62px;
  text-shadow: 1px 1px 8px rgb(0, 0, 0);
}
@media screen and (max-width: 768px) {
  .introduction__welcome h1 {
    font-size: 48px;
  }
}
.introduction__welcome p {
  width: 60%;
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  line-height: 1em;
  text-shadow: 1px 1px 8px rgb(0, 0, 0);
}
@media screen and (max-width: 768px) {
  .introduction__welcome p {
    font-size: 26px;
    width: 73%;
  }
}

.works {
  background: #f6f7fa;
  width: 100%;
  height: fit-content;
  padding-bottom: 50px;
}
.works h2 {
  text-align: center;
  color: #313131;
  font-weight: 800;
  line-height: 1;
  font-size: 32px;
  width: 65%;
  margin: auto;
  padding: 50px 0;
}
@media screen and (max-width: 768px) {
  .works h2 {
    font-size: 28px;
    width: 78%;
  }
}
.works__photos {
  display: flex;
  margin: auto;
  width: fit-content;
  height: 300px;
}
@media screen and (max-width: 768px) {
  .works__photos {
    flex-wrap: wrap;
    height: fit-content;
  }
}
.works__photos img {
  margin: 10px;
}
@media screen and (max-width: 768px) {
  .works__photos img {
    width: 80vw;
    margin: 10px auto;
  }
}

.about {
  background-color: #f5eff0;
  width: 100%;
  min-width: 800px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .about {
    flex-wrap: wrap;
    min-width: 0;
  }
}
.about img {
  width: 550px;
  height: fit-content;
  margin: 60px 30px;
}
@media screen and (max-width: 768px) {
  .about img {
    width: 80vw;
  }
}
.about__text {
  margin: 30px;
  width: 480px;
}
.about__intro {
  font-weight: 600;
}
.about p {
  line-height: 1.7em;
}

.quotations {
  background-image: url("./assets/6_Famille/293720900_5579237532140287_231894065122592073_n.jpg");
  background-repeat: no-repeat;
  background-position: center center; /* Centre l'image dans le bloc */
  width: 100%;
  height: 600px;
  object-fit: scale-down;
  z-index: 1;
  display: flex; /* Utilise Flexbox pour le premier bloc */
  align-items: center; /* Centre verticalement */
  justify-content: center; /* Centre horizontalement */
}
@media screen and (max-width: 768px) {
  .quotations {
    width: 100%;
    height: 400px;
  }
}
.quotations .alpha-grey {
  width: inherit;
  height: inherit;
  position: absolute;
  background-color: rgba(79, 79, 79, 0.4);
  z-index: 2;
}
.quotations__quotes {
  position: relative;
  z-index: 3;
  color: white;
  vertical-align: middle;
  display: flex; /* Utilise Flexbox pour le premier bloc */
  align-items: center; /* Centre verticalement */
  justify-content: center; /* Centre horizontalement */
  flex-wrap: wrap;
}
.quotations__quotes p {
  width: 60%;
  text-align: center;
  font-style: italic;
  font-size: 28px;
  font-weight: 500;
  line-height: 1.3em;
  text-shadow: 1px 1px 8px rgb(0, 0, 0);
  margin: 10px 0;
}
@media screen and (max-width: 768px) {
  .quotations__quotes p {
    font-size: 24px;
    width: 75%;
  }
}
.quotations__quotes__author {
  font-style: normal;
}

.page--nouveaunes div {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 960px;
}
@media screen and (max-width: 768px) {
  .page--nouveaunes div {
    max-height: none;
  }
}
.page--nouveaunes div p {
  width: 47%;
  height: fit-content;
  margin-right: 6%;
}
@media screen and (max-width: 768px) {
  .page--nouveaunes div p {
    width: 100%;
    margin-right: 0px;
  }
}

.tarifs-options .options-title {
  display: flex;
  align-items: center;
  width: 100%;
}
.tarifs-options .options-title .bar {
  height: 1px;
  width: 100%;
  background-color: #4d4547;
}
.tarifs-options .options-title h2 {
  font-weight: bold;
  font-size: 48px;
  width: fit-content;
  margin: 16px;
}
.tarifs-options h3 {
  font-size: 24px;
}
.tarifs-options .other-options {
  font-weight: bold;
  font-size: 27px;
}
.tarifs-options ul {
  padding-left: 24px;
}
.tarifs-options .other-options-list {
  padding-left: 8px;
  list-style-type: none;
}
.tarifs-options .other-options-list li::before {
  content: "-";
  margin-right: 16px;
}
.tarifs-options .other-options-list .paiement3x {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.page--contact svg {
  margin-right: 16px;
  font-size: 16px;
}

.form-title {
  display: flex;
  align-items: center;
  width: 100%;
}
.form-title .bar {
  height: 1px;
  background-color: #4d4547;
  flex: 1 1 auto;
}
.form-title h2 {
  font-weight: bold;
  font-size: 48px;
  margin: 16px;
  flex: 0 1 auto;
}
@media screen and (max-width: 768px) {
  .form-title h2 {
    font-size: 24px;
  }
}

#formulaire-contact {
  width: 620px;
  margin: auto;
}
@media screen and (max-width: 768px) {
  #formulaire-contact {
    width: 90%;
  }
}
#formulaire-contact .form-line {
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
#formulaire-contact .form-line .contact-list {
  width: 300px;
}
@media screen and (max-width: 768px) {
  #formulaire-contact .form-line .contact-list {
    width: 100%;
    margin-top: 4px;
  }
}
#formulaire-contact .form-line .contact-input--motif {
  width: 400px;
}
@media screen and (max-width: 768px) {
  #formulaire-contact .form-line .contact-input--motif {
    width: 100%;
    margin-top: 4px;
  }
}
#formulaire-contact .form-line .contact-input--nom {
  width: 220px;
}
@media screen and (max-width: 768px) {
  #formulaire-contact .form-line .contact-input--nom {
    width: 55vw;
    margin-bottom: 4px;
    margin-left: 15vw;
  }
}
#formulaire-contact .form-line .contact-input--prenom {
  width: 220px;
}
@media screen and (max-width: 768px) {
  #formulaire-contact .form-line .contact-input--prenom {
    width: 55vw;
    margin-bottom: 4px;
  }
}
#formulaire-contact .form-line .contact-input--telephone {
  width: 140px;
}
@media screen and (max-width: 768px) {
  #formulaire-contact .form-line .contact-input--telephone {
    width: 55vw;
    margin-bottom: 4px;
  }
}
#formulaire-contact .form-line .contact-input--email {
  width: 260px;
}
@media screen and (max-width: 768px) {
  #formulaire-contact .form-line .contact-input--email {
    width: 55vw;
    margin-bottom: 4px;
  }
}
#formulaire-contact p {
  font-size: 16px;
  line-height: 22px;
  font-style: italic;
}
#formulaire-contact textarea {
  width: 100%;
  margin: 10px 0;
}
